<template>
   <div class="misc-wrapper">
	  <b-link class="brand-logo">
		 <vuexy-logo />
	  </b-link>

	  <div class="misc-inner p-2 p-sm-3">
		 <div class="w-100 text-center">
			<h2 class="mb-1">
			   Δεν έχετε πρόσβαση
			</h2>
			<p class="mb-2">
			   Δεν έχετε πρόσβαση στη συγκεκριμένη σελίδα. Παρακαλούμε επιστρέψτε στην Αρχική
			</p>
			<b-button
				:to="loginRoute()"
				class="mb-1 btn-sm-block"
				variant="primary"
			>επιστροφή στην Αρχική
			</b-button>
			<b-img
				:src="imgUrl"
				alt="Not authorized page"
				fluid
			/>
		 </div>
	  </div>
   </div>
</template>

<script>
/* eslint-disable global-require */
import {BButton, BImg, BLink} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import {getHomeRouteForLoggedInUser} from '@/auth/utils'

export default {
   components: {
	  BLink, BImg, BButton, VuexyLogo,
   },
   data() {
	  return {
		 downImg: require('@/assets/images/pages/not-authorized.svg'),
	  }
   },
   computed: {
	  imgUrl() {
		 if (store.state.appConfig.layout.skin === 'dark') {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
			return this.downImg
		 }
		 return this.downImg
	  },
   },
   methods: {
	  loginRoute() {
		 const user = JSON.parse(localStorage.getItem('userData'))
		 return getHomeRouteForLoggedInUser(user ? user.role : null)
	  },
   },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
